import React from "react";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head.js";

var imageArray = [
  "https://res.cloudinary.com/benjifri/image/upload/v1702539799/artnow/2023/homepage/1_benji_friedman_suburb.jpg",
  "https://res.cloudinary.com/benjifri/image/upload/v1702539799/artnow/2023/homepage/2_benji_friedman_suburb.jpg",
  "https://res.cloudinary.com/benjifri/image/upload/v1702539799/artnow/2023/homepage/3_benji_friedman_suburb.jpg",
  "https://res.cloudinary.com/benjifri/image/upload/v1702539799/artnow/2023/homepage/4_benji_friedman_suburb.jpg",
  "https://res.cloudinary.com/benjifri/image/upload/v1702539799/artnow/2023/homepage/5_benji_friedman_suburb.jpg",
];

// var imageNumberChoice = Math.floor(Math.random() * 5);

// console.log(imageNumberChoice);

// var imageNumberChoice = Math.floor(Math.random() * 18);
// var imageChoiceSRC = imageNumberChoice;

//var imageChoiceSRC = "https://res.cloudinary.com/benjifri/image/upload/v1667542242/artnow/homepage/benji-friedman-phone-baboon-november-2022.jpg";

//owl
//var imageChoiceSRC = "https://res.cloudinary.com/benjifri/image/upload/v1669569310/artnow/homepage/blue-owl-benji-friedman.jpg";

//winter mountain
//var imageChoiceSRC =
//"https://res.cloudinary.com/benjifri/image/upload/v1667542242/artnow/homepage/benji-friedman-phone-baboon-november-2022.jpg";

// the agapanthus
// imageChoiceSRC =
//   "https://assets0.ello.co/uploads/asset/attachment/6410555/ello-optimized-02a47ea7.jpg";

// classic kiosk
//var imageChoiceSRC = "https://res.cloudinary.com/benjifri/image/upload/v1659492722/benjifriedman.com%202022/Ttiled_Kiosk_55-2.jpg";
//var imageChoiceSRC = "https://res.cloudinary.com/benjifri/image/upload/v1671161772/artnow/homepage/benji-friedman-artnowworld-december-2022-hello.jpg";

// classic agapanthus
// imageChoiceSRC = https://assets0.ello.co/uploads/asset/attachment/6410555/ello-optimized-02a47ea7.jpg

//https://res.cloudinary.com/benjifri/image/upload/v1664081589/artnow/homepage/1-artnow-september-2022-Benji-Friedman.jpg

// if (imageNumberChoice >= 5) {
//   imageChoiceSRC =
//     "https://res.cloudinary.com/benjifri/image/upload/v1664081076/artnow/homepage/2-artnow-september-2022-Benji-Friedman.jpg";
// } else if (imageChoiceSRC === 3) {
//   imageChoiceSRC =
//     "https://res.cloudinary.com/benjifri/image/upload/v1664081589/artnow/homepage/3-artnow-september-2022-Benji-Friedman.jpg";
// } else if (imageChoiceSRC === 2 || imageChoiceSRC === 1) {
//   imageChoiceSRC =
//     "https://res.cloudinary.com/benjifri/image/upload/v1664081589/artnow/homepage/4-artnow-september-2022-Benji-Friedman.jpg";
// } else {
//   imageChoiceSRC =
//     "https://res.cloudinary.com/benjifri/image/upload/v1664081589/artnow/homepage/4-artnow-september-2022-Benji-Friedman.jpg";
// }

// imageChoiceSRC =
//   "https://res.cloudinary.com/benjifri/image/upload/v1568518225/IMG_5830_1_ow8jdb.jpg";

// imageChoiceSRC =
//     "https://res.cloudinary.com/benjifri/image/upload/v1664081076/artnow/homepage/2-artnow-september-2022-Benji-Friedman.jpg";

const IndexPage = ({ location }) => {
  const month = new Date().toLocaleString("en-US", { month: "long" });
  const year = new Date().getFullYear();

  var imageChoiceSRC =
    imageArray[Math.floor(Math.random() * imageArray.length)];

  return (
    <Layout location={location}>
      <Head title="Home" />
      {/* <img src={imageChoiceSRC} alt="homepage" /> */}
      <p>
        A website for artists of all kinds / types / media to share with the
        world
      </p>

      <img src={imageChoiceSRC} alt="homepage" />
      {/* <img
        src="https://res.cloudinary.com/benjifri/image/upload/c_scale,f_auto/v1671161772/artnow/homepage/benji-friedman-artnowworld-december-2022-hello.jpg"
        alt="Artist directory of profiles and art, worldwide"
        style={{ marginBottom: "13px" }}
      /> */}
      {/* <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1676619677/benjifriedman.com%202023/shipping%20containers%20freeway/119_freeway_shipping_container_benji_february_2023.jpg"
        alt="Artist directory of profiles and art, worldwide"
        style={{ marginBottom: "13px" }}
      /> */}

      {/* <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1687841714/yellow_box_bia8ak.jpg"
        alt="Artist directory of profiles and art, worldwide"
        style={{ marginBottom: "13px" }}
      /> */}
      {/* ^^^ good yellow crate */}
      {/* <p style={{ marginBottom: 8 }}>
        <em>Made with ❤️ in Berkeley, CA</em>
      </p> */}

      <p>
        {month} {year}
      </p>
      {/* <p>
        <b>
          <Link to="/collaboration">Collaborations (New)</Link>
        </b>
      </p> */}
    </Layout>
  );
};

export default IndexPage;
